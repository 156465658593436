export const EMPTY_COLLECTION_TEXT = 'This collection needs some love! Add some products now.';

export const EMPTY_HUB_MESSAGE = "Create some collections and share them with your followers. We can't wait to see what you build!";

export const REMINDER_MESSAGE_PROFILE = "Remember to go to your Manage Content tab and publish this collection when you think it's ready.";

export const REMINDER_MESSAGE_HUB = "Remember to come back and publish this collection on your profile page when it's ready!";

export const PROFILE_PAGE_REMINDER = ' So your followers can see it on your Profile Page.';

export const COLLECTIONS_HEADER = 'Add products to collections and make it easy for your followers to find products you love.';

export const NEW_COLLECTION_SUMMARY_TEXT = 'One collection has been created on your Manage Content Page. Now you can add some products to it.';

export const PROFILE_NAME_PLACEHOLDER = 'Your Store Name';
export const PROFILE_NAME_ERROR_COPY = 'Please give your store a memorable name';

export const PROFILE_STYLES_PLACEHOLDER = 'Choose Your Personal Style';
export const PROFILE_STYLES_ERROR_COPY = 'Please choose your fashion styles';

export const PROFILE_BIO_PLACEHOLDER =
  'Tell people about your store, and let them know why you love building collections and what your collections say about your personal style. Remind them that they can share your collections, as well! 😎';

export const PROFILE_PICTURE_ERROR_COPY = 'Profile picture is required';

export const PROFILE_PENDING_REVIEW_COPY =
  "Great News! You're almost there. We're reviewing your profile now and your followers will see the update once it's approved. For your profile to go live please create some content.";

export const PROFILE_APPROVED_COPY = 'Congrats! Your profile update has been approved! Your followers can see the update now.';

export const PROFILE_APPROVED_NO_CONTENT_COPY =
  'Congrats! Your profile has been approved. Begin creating at least one piece of shoppable content or one collection to make your profile page active and to share with your followers!';

export const PROFILE_DECLINED_COPY =
  'Part of your profile update has been declined for inappropriate content. It has been reversed to the previous version.';

export const PROFILE_NOT_ACTIVE_COPY =
  'You need at least one piece of shoppable content or one collection to make your profile page active. After your profile is active, your followers will be able to find you on Zappos and you’ll be able to share your page on social media.';

export const INFLUENCER_PROFILE_REMINDER = 'For people to find you and be inspired by your styles, please complete your profile.';

export const EMPTY_COLLECTION_MESSAGE =
  'Now you can go explore our HUGE collection of products and add them to this collection by clicking on "Add to Collections" dropdown on product details page!';

export const NO_PRODUCTS_MESSAGE = 'Come back when you’re done and you’ll see your collection here.';

export const UPLOAD_COLLECTION_IMAGE_NOTE =
  'A picture will get your collection more attention. Customers love a photo that shows the concept of the collection.';

export const EMPTY_PRODUCT_REPORT_MESSAGE = 'There are no products found for the selected time period.';

export const EMPTY_COLLECTION_REPORT_MESSAGE = 'There are no collections found for the selected time period.';

export const EMPTY_REPORT_MESSAGE =
  'There are no metrics for the selected time period. Please adjust the time period to view collection and product metrics.';

export const BRAND_INFLUENCERS_TITLE = 'Style inspiration from our trusted brands';

export const YOU_JUST_MISSED_IT = 'You just missed it!';

export const PRODUCTS_RAN_OUT_OF_STOCK = 'Sorry, all of these products were so popular that we ran out of stock.';

export const SOLD_LAST_ITEM_IN_THIS_SIZE = 'Sorry, we just sold the last item in this size.';

export const PAGELOAD_ERROR_MESSAGE = 'Sorry we’re having trouble loading this content right now. Please try again later.';
